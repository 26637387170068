import React from 'react'
import authRoute from './authRoute'
import Step1 from 'views/steps/Step1'
import Step2 from 'views/steps/Step2'
import Step3 from 'views/steps/Step3'
import Step4 from 'views/steps/Step4'
import { PERMISSION } from 'utils/mapUserPermissions'

export const publicRoutes = [
    ...authRoute,
    {
        key: 'contact',
        path: '/contact',
        component: React.lazy(() => import('views/ContactPage')),
        layout: 'main',
        authority: [],
    },

    {
        key: 'terms',
        path: '/terms',
        component: React.lazy(() => import('views/TermsAndConditions')),
        layout: 'main', 
        authority: [],
    },

    {
        key: 'privacy',
        path: '/privacy',
        component: React.lazy(() => import('views/PrivacyPolicy')),
        layout: 'main',
        authority: [],
    }
]

export const protectedRoutes = [
    {
        key: 'home',
        path: '/home',
        component: React.lazy(() => import('views/Home')),
        tenderGuard: true,
        children: [
            {
                key: 'step1',
                path: '/home/user-details',
                component: <Step1/>,
                tenderGuard: true
            },
            {
                key: 'step2',
                path: '/home/company-info',
                component: <Step2/>,
                tenderGuard: true

            },
            {
                key: 'step3',
                path: '/home/background-details',
                component: <Step3/>,
                tenderGuard: true

            },
            {
                key: 'step4',
                path: '/home/tender-requirements',
                component: <Step4/>,
                tenderGuard: true
            }
        ],
        authority: [PERMISSION.USER],
    },
    {
        key: 'tenders',
        path: '/tenders',
        component: React.lazy(() => import('views/tenders/Tenders')),
        authority: [PERMISSION.USER],
    },
    {
        key: 'rfqForm',
        path: '/rfqForm',
        component: React.lazy(() => import('views/steps/Step5')),
        authority: [PERMISSION.USER],
    },
    {
        key: 'supplier-tenders',
        path: '/supplier-tenders',
        component: React.lazy(() => import('views/supplier-tenders/SupplierTenders')),
        authority: [PERMISSION.SUPPLIER],
    },
    {
        key: 'supplier-tenders',
        path: '/supplier-tenders/:id',
        component: React.lazy(() => import('views/supplier-tenders/SupplierTenderDetail')),
        authority: [PERMISSION.SUPPLIER],
    },
    {
        key: 'supplier-onboarding',
        path: '/supplier-onboarding',
        component: React.lazy(() => import('views/auth/SupplierOnboarding')),
        authority: [PERMISSION.SUPPLIER],
    },

    //Admin view supplier list
    {
        key: 'suppliers',
        path: '/suppliers',
        component: React.lazy(() => import('views/suppliers/Suppliers')),
        authority: [PERMISSION.ADMIN],
    },
    {
        key: 'suppliers',
        path: '/suppliers/:id',
        component: React.lazy(() => import('views/suppliers/SupplierDetail')),
        authority: [PERMISSION.ADMIN],
    },

    //Admin view company list
    {
        key: 'companies',
        path: '/companies',
        component: React.lazy(() => import('views/companies/Companies')),
        authority: [PERMISSION.ADMIN],
    },
    {
        key: 'companies',
        path: '/companies/:id',
        component: React.lazy(() => import('views/companies/CompanyDetails')),
        authority: [PERMISSION.ADMIN],
    },

    //Admin view and manage coupons
    {
        key: 'coupons',
        path: '/coupons',
        component: React.lazy(() => import('views/coupons/Coupons.js')),
        authority: [PERMISSION.ADMIN],
    },

    {
        key: 'accessDenied',
        path: '/access-denied',
        component: React.lazy(() => import('views/pages/AccessDenied')),
    },
]