export const termClient = [
    {
        title: '1. Acceptance of Terms',
        children: [
            'By accessing or using the Tenderfy.io platform ("the Service"), you agree to be bound by these Terms and Conditions.',
        ],
    },
    {
        title: '2. Description of Service',
        children: [
            'Tenderfy.io is an automated tendering platform for R&D Tax Credits, connecting prospective claimants with suppliers.',
        ],
    },
    {
        title: '3. User Registration',
        children: [
            '3.1. You must register an account to use the Service.',
            '3.2. You agree to provide accurate and complete information.',
        ],
    },
    {
        title: '4. Fees and Payment',
        children: [
            '4.1. The fee for using the Service is £499 unless any discretionary discounts / promotional codes are advertised.',
            '4.2. Payment is due upon completion of the request for quotation (RFQ). A receipt will be issued upon receipt of payment.',
            '4.3. All fees are non-refundable unless otherwise stated.',
        ],
    },
    {
        title: '5. User Obligations',
        children: [
            '5.1. You agree to use the Service in compliance with all applicable laws.',
            '5.2. You will not misuse the Service or interfere with its functionality.',
        ],
    },
    {
        title: '6. Intellectual Property',
        children: [
            '6.1. All content and functionality on Tenderfy.io is the property of Tenderfy R&D Tax Ltd.',
            '6.2. You may not reproduce, distribute, or create derivative works without our permission.',
        ],
    },
    {
        title: '7. Privacy and Data Protection',
        children: [
            '7.1. Our collection and use of your data is governed by our Privacy Policy.',
            '7.2. You consent to the collection and use of your data as described in the Privacy Policy.',
        ],
    },
    {
        title: '8. Limitation of Liability',
        children: [
            '8.1. Tenderfy R&D Tax Ltd is not liable for any indirect, incidental, or consequential damages.',
            '8.2. Our total liability is limited to the amount you paid for the Service.',
        ],
    },
    {
        title: '9. Termination',
        children: [
            '9.1. We reserve the right to terminate or suspend your account for violations of these terms.',
            '9.2. You may terminate your account at any time by contacting us.',
        ],
    },
    {
        title: '10. Changes to Terms',
        children: [
            'We may modify these terms at any time. Continued use of the Service constitutes acceptance of modified terms.',
        ],
    },
    {
        title: '11. Governing Law',
        children: ['These terms are governed by the laws of England & Wales.'],
    },
    {
        title: '12. Contact Information',
        children: [
            'For questions about these terms, contact us at info@tenderfy.io.',
        ],
    },
]

export const termSupplier = [
    {
        title: '1. Acceptance of Terms',
        children: [
            'By registering as a supplier on the Tenderfy.io platform ("the Service"), you agree to be bound by these Terms and Conditions.',
        ],
    },
    {
        title: '2. Description of Service',
        children: [
            'Tenderfy.io is an automated tendering platform connecting R&D Tax Credit suppliers with prospective claimants.',
        ],
    },
    {
        title: '3. Supplier Registration',
        children: [
            '3.1. You must register and be approved as a supplier to use the Service.',
            '3.2. You agree to provide accurate and complete information about your services.',
            '3.3. You are responsible for maintaining the confidentiality of your account.',
        ],
    },
    {
        title: '4. Fees and Commission',
        children: [
            '4.1. Tenderfy R&D Tax Ltd will charge a commission of 15% on successful tenders for the duration of the term agreed between the supplier and the client.',
            '4.2. This commission is necessary for the contribution to the marketing, technology and ongoing maintenance of the platform for the services provided by Tenderfy R&D Tax Ltd.',
            '4.3. The supplier agrees to pay this commission within 14 days of receiving payment from the client.',
            "4.4. Tenderfy R&D Tax Ltd reserves the right to audit the supplier's records to ensure accurate reporting of successful tenders and commission payments.",
            "4.5. Failure to pay the commission may result in suspension or termination of the supplier's account and legal action to recover unpaid fees.",
            '4.6. All invoice requests must be sent to finance@tenderfy.io within 7 days of receipt of payment from the client.',
        ],
    },
    {
        title: '5. Supplier Obligations',
        children: [
            '5.1. You agree to provide accurate and up-to-date information about your services.',
            '5.2. You will respond to client enquiries in a timely manner.',
            '5.3. You will maintain any required licenses, certifications, or qualifications.',
            '5.4. You agree to promptly and accurately report all successful tenders resulting from connections made through the Tenderfy.io platform.',
            '5.5. You will not attempt to circumvent the commission structure by directly contracting with clients introduced through the platform outside of the Tenderfy.io system.',
        ],
    },
    {
        title: '6. Service Quality and Disputes',
        children: [
            '6.1. You are solely responsible for the quality of services provided to clients.',
            '6.2. Tenderfy R&D Tax Ltd is not liable for disputes between suppliers and clients.',
            '6.3. We reserve the right to remove suppliers who receive consistent negative feedback.',
        ],
    },
    {
        title: '7. Intellectual Property',
        children: [
            '7.1. You retain rights to your own content but grant us a license to use it on the platform.',
            '7.2. You will not infringe on the intellectual property rights of others.',
        ],
    },
    {
        title: '8. Privacy and Data Protection',
        children: [
            '8.1. Our collection and use of your data is governed by our Privacy Policy.',
            '8.2. You agree to handle client data in compliance with applicable data protection laws.',
        ],
    },
    {
        title: '9. Limitation of Liability',
        children: [
            '9.1. Tenderfy R&D Tax Ltd is not liable for any indirect, incidental, or consequential damages.',
            '9.2. Our total liability is limited to the amount of fees paid by you in the last 12 months.',
        ],
    },
    {
        title: '10. Termination',
        children: [
            '10.1. We reserve the right to terminate or suspend your account for violations of these terms.',
            '10.2. You may terminate your account at any time by contacting us.',
        ],
    },
    {
        title: '11. Changes to Terms',
        children: [
            'We may modify these terms at any time. Continued use of the Service constitutes acceptance of modified terms.',
        ],
    },
    {
        title: '12. Governing Law',
        children: ['These terms are governed by the laws of England & Wales.'],
    },
    {
        title: '13. Contact Information',
        children: [
            'For questions about these terms, contact us at info@tenderfy.io.',
        ],
    },
]
