import React from 'react'
import PropTypes from 'prop-types'
import useAuthority from 'utils/hooks/useAuthority'
import { useSelector } from 'react-redux'
import userPermissions from 'utils/mapUserPermissions'

const AuthorityCheck = (props) => {
    const { userAuthority = [], authority = [], children } = props
    const {user} = useSelector((state) => state.auth)
    const permissions = userPermissions(user)

    const roleMatched = useAuthority(permissions, authority)

    return roleMatched ? children : <></>
}

AuthorityCheck.propTypes = {
    userAuthority: PropTypes.array,
    authority: PropTypes.array,
}

export default AuthorityCheck
