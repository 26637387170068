import React from 'react'

const authRoute = [
    {
        key: 'homePage',
        path: `/`,
        component: React.lazy(() => import('views/auth/HomePage')),
        authority: [],
    },
    {
        key: 'signIn',
        path: `/sign-in`,
        component: React.lazy(() => import('views/auth/SignIn')),
        authority: [],
    },
    {
        key: 'signUp',
        path: `/sign-up`,
        component: React.lazy(() => import('views/auth/SignUp')),
        authority: [],
    },
    {
        key: 'signUpSupplier',
        path: `/sign-up-supplier`,
        component: React.lazy(() => import('views/auth/SignUpSupplier')),
        authority: [],
    },
    {
        key: 'forgotPassword',
        path: `/forgot-password`,
        component: React.lazy(() => import('views/auth/ForgotPassword')),
        authority: [],
    },
    {
        key: 'resetPassword',
        path: `/reset-password`,
        component: React.lazy(() => import('views/auth/ResetPassword')),
        authority: [],
    },
    {
        key: 'setPassword',
        path: `/set-password/:id/:token`,
        component: React.lazy(() => import('views/auth/ResetPassword')),
        authority: [],
    },
    {
        key: 'resetPasswordConfirm',
        path: `/reset-password-confirm/:id/:token`,
        component: React.lazy(() => import('views/auth/ResetPassword')),
        authority: [],
    },
    
]

export default authRoute
