import { Loading } from 'components/shared'
import { Button, FormContainer, FormItem, Input } from 'components/ui'
import { STEPS } from 'components/ui/utils/constant'
import { Field, Form, Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
    apiGetEmployeeProfile,
    apiUpdateEmployeeProfile,
} from 'services/StepService'
import { setIsEmployeeCompleted, stepChange } from 'store/step/stepSlice'
import * as Yup from 'yup'
const formikRef = React.createRef()

const validationSchema = Yup.object().shape({
    contact_name: Yup.string().required('Please enter your contact name').nullable(),
    role: Yup.string().required('Please enter your role').nullable(),
    phone_number: Yup.string().required('Please enter your phone number').nullable(),
    email: Yup.string()
        .email('Invalid email address')
        .required('Please enter your email address').nullable(),
})

export default function Step1() {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [employee, setEmployee] = useState()
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        dispatch(stepChange(STEPS.EMPLOYEE))
        fetchEmployee()
    }, [])

    const fetchEmployee = async () => {
        try {
            setLoading(true)
            const resp = await apiGetEmployeeProfile()
            if (resp.status === 200) {
                const data = resp?.data?.data?.employee
                setEmployee(data)
                dispatch(setIsEmployeeCompleted(resp?.data?.data?.is_completed))
            }
            setLoading(false)
        } catch (error) {
            setLoading(false)
        }
    }

    const onNext = async (values, setSubmitting) => {
        try {
            setSubmitting(true)
            const result = await apiUpdateEmployeeProfile(values)
            if (result.data.status === 'success') {
                dispatch(setIsEmployeeCompleted(result?.data?.data?.is_completed))
                navigate('/home/company-info')
            }
            setSubmitting(false)
        } catch (error) {
            setSubmitting(false)
        }
    }
    return (
        <div className="w-full flex flex-col px-4 py-8 items-center">
            {loading ? (
                <Loading loading={loading} />
            ) : (
                <Formik
                    enableReinitialize
                    validationSchema={validationSchema}
                    innerRef={formikRef}
                    initialValues={employee}
                    onSubmit={(values, { setSubmitting }) => {
                        setSubmitting(true)
                        onNext(values, setSubmitting)
                    }}
                >
                    {({ touched, errors, isSubmitting }) => (
                        <div className="w-full">
                            <Form>
                                <FormContainer>
                                    <div className="bg-slate-50 p-7 rounded-md">
                                        <FormItem
                                            label="Contact Name"
                                            invalid={
                                                errors?.contact_name &&
                                                touched.contact_name
                                            }
                                            errorMessage={errors?.contact_name}
                                        >
                                            <Field
                                                autoComplete="off"
                                                name="contact_name"
                                                placeholder="Contact Name"
                                                component={Input}
                                            />
                                        </FormItem>
                                        <FormItem
                                            label="Role within company"
                                            invalid={
                                                errors.role && touched.role
                                            }
                                            errorMessage={errors.role}
                                        >
                                            <Field
                                                autoComplete="off"
                                                name="role"
                                                placeholder="Job Title"
                                                component={Input}
                                            />
                                        </FormItem>
                                        <FormItem
                                            label="Email Address"
                                            invalid={
                                                errors.email && touched.email
                                            }
                                            errorMessage={errors.email}
                                        >
                                            <Field
                                                type="email"
                                                autoComplete="off"
                                                name="email"
                                                placeholder="Email Address"
                                                component={Input}
                                            />
                                        </FormItem>
                                        <FormItem
                                            label="Phone Number"
                                            invalid={
                                                errors.phone_number &&
                                                touched.phone_number
                                            }
                                            errorMessage={errors.phone_number}
                                        >
                                            <Field
                                                type="tel"
                                                autoComplete="off"
                                                name="phone_number"
                                                placeholder="Phone Number"
                                                component={Input}
                                            />
                                        </FormItem>
                                    </div>

                                    <div className="mt-6 text-right">
                                        <Button
                                            block
                                            type="submit"
                                            variant="solid"
                                            loading={isSubmitting}
                                        >
                                            {'Next'}
                                        </Button>
                                    </div>
                                </FormContainer>
                            </Form>
                        </div>
                    )}
                </Formik>
            )}
        </div>
    )
}