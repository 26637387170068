import { FormNumericInput, Loading } from 'components/shared';
import {
    Button,
    DatePicker,
    FormContainer,
    FormItem,
    Input,
    Notification,
    Radio,
    Select,
    toast,
} from 'components/ui';
import { STEPS } from 'components/ui/utils/constant';
import { getErrorMessage } from 'components/ui/utils/errorMessage';
import dayjs from 'dayjs';
import { Field, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
    apiGetCompanyBackgroundInfo,
    apiUpdateCompanyBackgroundInfo,
} from 'services/StepService';
import { setIsBackgroundCompleted, stepChange } from 'store/step/stepSlice';
import * as Yup from 'yup';

const formikRef = React.createRef();

// Validation schema
const validationSchema = Yup.object().shape({
    turnover_or_balance_sheet: Yup.string().required('Please enter turnover or balance sheet').nullable(),
    period_of_claim: Yup.date().required('Please enter period of claim').nullable(),
    headcount_full_time_staff: Yup.number().required('Please enter headcount full-time staff').nullable(),
    made_prior_claim: Yup.bool().required('Please select if a prior claim was made').nullable(),
    number_of_prior_claims: Yup.number()
        .when('made_prior_claim', {
            is: true,
            then: Yup.number().required('Please enter the number of prior claims').nullable(),
            otherwise: Yup.number().nullable(),
        }),
    average_qualifying_expenditure: Yup.number()
        .when('made_prior_claim', {
            is: true,
            then: Yup.number().required('Please enter the average qualifying expenditure').nullable(),
            otherwise: Yup.number().nullable(),
        }),
    expenditure_category: Yup.string()
        .when('made_prior_claim', {
            is: true,
            then: Yup.string().required('Please Select Your Expenditure Category').nullable(),
            otherwise: Yup.string().nullable(),
        }),
    r_and_d_scheme: Yup.string()
        .when('made_prior_claim', {
            is: true,
            then: Yup.string().required('Please select the R&D scheme').nullable(),
            otherwise: Yup.string().nullable(),
        }),
    previous_claims_hmrc_enquiry: Yup.bool().required('Please select if previous claims were subject to HMRC enquiry').nullable(),
    previous_claim_hmrc_enquiry_date: Yup.date()
        .when('previous_claims_hmrc_enquiry', {
            is: true,
            then: Yup.date().required('Please enter the enquiry date').nullable(),
            otherwise: Yup.date().nullable(),
        }),
    number_of_projects_to_assess: Yup.number().required('Please enter the number of projects to assess').nullable(),
    number_of_individuals_involved: Yup.number().required('Please enter the number of individuals involved').nullable(),
    estimated_payroll_cost: Yup.number().required('Please enter the estimated payroll cost').nullable(),
    estimated_external_contractors_cost: Yup.number().required('Please enter the estimated external contractors cost').nullable(),
    estimated_software_materials_cost: Yup.number().required('Please enter the estimated software/materials cost').nullable(),
    r_and_d_claims_outstanding_hmrc: Yup.bool().required('Please select if there are outstanding R&D claims with HMRC').nullable(),
    r_and_d_claims_outstanding_period: Yup.string()
        .when('r_and_d_claims_outstanding_hmrc', {
            is: true,
            then: Yup.string().required('Please select the outstanding claims period').nullable(),
            otherwise: Yup.string().nullable(),
        }),
    r_and_d_claims_outstanding_submitted_date: Yup.date()
        .when('r_and_d_claims_outstanding_hmrc', {
            is: true,
            then: Yup.date().required('Please enter the submission date').nullable(),
            otherwise: Yup.date().nullable(),
        }),
    review_previous_claims: Yup.bool().required('Please select if previous claims are to be reviewed').nullable(),
    review_previous_claims_year: Yup.date()
        .when('review_previous_claims', {
            is: true,
            then: Yup.date().required('Please enter the review year').nullable(),
            otherwise: Yup.date().nullable(),
        }),
    r_and_d_activities_outsourced_overseas: Yup.bool().required('Please select if R&D activities are outsourced overseas').nullable(),
    part_of_group: Yup.bool().required('Please select if the company is part of a group').nullable(),
    number_of_companies_in_group: Yup.number()
        .when('part_of_group', {
            is: true,
            then: Yup.number().required('Please enter the number of companies in the group').nullable(),
            otherwise: Yup.number().nullable(),
        }),
    received_grant_funding: Yup.bool().required('Please select if grant funding was received').nullable(),
    grant_funding_amount: Yup.number()
        .when('received_grant_funding', {
            is: true,
            then: Yup.number().required('Please enter the grant funding amount').nullable(),
            otherwise: Yup.number().nullable(),
        }),
    type_of_funding: Yup.string()
        .when('received_grant_funding', {
            is: true,
            then: Yup.string().required('Please enter the type of funding').nullable(),
            otherwise: Yup.string().nullable(),
        }),
    date_of_funding: Yup.date()
        .when('received_grant_funding', {
            is: true,
            then: Yup.date().required('Please enter the date of funding').nullable(),
            otherwise: Yup.date().nullable(),
        }),
    company_incorporation_date: Yup.date().required('Please enter the company incorporation date').nullable(),
});

// Define rndSchemeOptions and companyBackgroundDetails
const rndSchemeOptions = [
    { value: 'SME', label: 'SME' },
    { value: 'RDEC', label: 'RDEC' },
    { value: 'Hybrid', label: 'Hybrid SME/RDEC' },
    { value: 'HighIntensiveSME', label: 'High Intensive SME' },
    { value: 'MergedScheme', label: 'Merged R&D Scheme' },
];


export default function Step3() {
    const dispatch = useDispatch();
    const [companyBackgroundDetails, setCompanyBackgroundDetails] = useState();
    const [loading, setLoading] = useState(false);
    const [isSubmitRegistration, setIsSubmitRegistration] = useState(false);
    const [disableForm, setDisableForm] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(stepChange(STEPS.BACKGROUND_DETAILS));
        fetchCompanyBackgroundInfo();
    }, [])

    const fetchCompanyBackgroundInfo = async () => {
        try {
            setLoading(true);
            const resp = await apiGetCompanyBackgroundInfo();
            if (resp.status === 200) {
                const is_completed = resp?.data?.data?.is_completed;
                dispatch(setIsBackgroundCompleted(is_completed));
                setDisableForm(is_completed);
                const data = resp?.data?.data?.background_details;
                pathFormValue(data);
            }
            setLoading(false);
        } catch (error) {
            toast.push(
                <Notification title={'Message'} type={'danger'}>
                    {getErrorMessage(error)}
                </Notification>
            );
            setLoading(false);
        }
    };

    const pathFormValue = (data) => {
        const init = {
            ...data,
        };
        const dateFields = [
            'company_incorporation_date',
            'period_of_claim',
            'r_and_d_claims_outstanding_submitted_date',
            'previous_claim_hmrc_enquiry_date',
            'review_previous_claims_year',
            'date_of_funding',
        ];
        dateFields.forEach((field) => {
            if (init[field]) {
                init[field] = new Date(init[field]);
            }
        });

        setCompanyBackgroundDetails(init);
    };

    const onNext = async (values, setSubmitting) => {
        setSubmitting(true);
        try {
            const payload = {
                ...values,
            };

            const dateFields = [
                'company_incorporation_date',
                'period_of_claim',
                'r_and_d_claims_outstanding_submitted_date',
                'previous_claim_hmrc_enquiry_date',
                'review_previous_claims_year',
                'date_of_funding',
                'r_and_d_claims_outstanding_period' // Still format this but in YYYY-MM-DD
            ];

            dateFields.forEach((field) => {
                if (payload[field]) {
                    payload[field] = dayjs(payload[field]).format('YYYY-MM-DD');
                }
            });

            const result = await apiUpdateCompanyBackgroundInfo(payload);
            if (result.status === 200) {
                const is_completed = result?.data?.data?.is_completed;
                dispatch(setIsBackgroundCompleted(is_completed));
                navigate('/home/tender-requirements');
            }
            setSubmitting(false);
        } catch (error) {
            toast.push(
                <Notification title={'Error'} type={'danger'}>
                    {getErrorMessage(error)}
                </Notification>
            );
            setSubmitting(false);
        }
    };

    return (
        <div className="w-full flex flex-col px-4 py-8 items-center">
            {loading ? (
                <Loading loading={loading} />
            ) : (
                <Formik
                    enableReinitialize
                    validationSchema={validationSchema}
                    innerRef={formikRef}
                    initialValues={companyBackgroundDetails}
                    onSubmit={(values, { setSubmitting }) => {
                        setSubmitting(true);
                        onNext(values, setSubmitting);
                    }}
                >
                    {({ values, touched, errors, isSubmitting }) => (
                        <div className="w-full">
                            <Form>
                                <FormContainer>
                                    <Loading
                                        loading={isSubmitRegistration}
                                        type="cover"
                                    >
                                        <div
                                            style={{ height: '67vh' }}
                                            className="bg-slate-50 p-7 rounded-md overflow-auto"
                                        >
                                            {/* Period of Claim */}
                                            <div className="flex flex-row gap-4">
                                                <FormItem
                                                    className="flex-1"
                                                    label="Period of Claim"
                                                    invalid={
                                                        errors.period_of_claim &&
                                                        touched.period_of_claim
                                                    }
                                                    errorMessage={
                                                        errors.period_of_claim
                                                    }
                                                >
                                                    <Field name="period_of_claim">
                                                        {({ field, form }) => (
                                                            <DatePicker
                                                                field={field}
                                                                form={form}
                                                                value={
                                                                    values?.period_of_claim
                                                                }
                                                                format="DD-MM-YYYY"
                                                                onChange={(
                                                                    date
                                                                ) => {
                                                                    form.setFieldValue(
                                                                        field.name,
                                                                        date
                                                                    );
                                                                }}
                                                            />
                                                        )}
                                                    </Field>
                                                </FormItem>
                                            </div>

                                            {/* Headcount of Full-Time Staff */}
                                            <div className="flex flex-row gap-4">
                                                <FormItem
                                                    className="flex-1"
                                                    label="Fulltime Equivalent Headcount"
                                                    invalid={
                                                        errors.headcount_full_time_staff &&
                                                        touched.headcount_full_time_staff
                                                    }
                                                    errorMessage={
                                                        errors.headcount_full_time_staff
                                                    }
                                                >
                                                    <Field
                                                        type="number"
                                                        name="headcount_full_time_staff"
                                                        placeholder=""
                                                        component={Input}
                                                    />
                                                </FormItem>
                                            </div>

                                            {/* Turnover or Balance Sheet */}
                                            <div className="flex flex-row gap-4">
                                                <FormItem
                                                    className="flex-1"
                                                    label="Turnover"
                                                    invalid={
                                                        errors.turnover_or_balance_sheet &&
                                                        touched.turnover_or_balance_sheet
                                                    }
                                                    errorMessage={
                                                        errors.turnover_or_balance_sheet
                                                    }
                                                >
                                                    <Field name="turnover_or_balance_sheet">
                                                        {({ field, form }) => (
                                                            <FormNumericInput
                                                                form={form}
                                                                inputSuffix={'£'}
                                                                thousandSeparator={
                                                                    true
                                                                }
                                                                field={field}
                                                                decimalScale={3}
                                                                placeholder=""
                                                                onValueChange={(
                                                                    e
                                                                ) => {
                                                                    let value =
                                                                        e.floatValue;
                                                                    form.setFieldValue(
                                                                        field.name,
                                                                        value
                                                                    );
                                                                }}
                                                                value={field.value}
                                                            />
                                                        )}
                                                    </Field>
                                                </FormItem>
                                            </div>

                                            {/* Made a Claim Prior to This One */}
                                            <div className="flex flex-row gap-4">
                                                <FormItem
                                                    className="flex-1"
                                                    label="Made a Claim Prior to This One"
                                                    invalid={
                                                        errors.made_prior_claim &&
                                                        touched.made_prior_claim
                                                    }
                                                    errorMessage={
                                                        errors.made_prior_claim
                                                    }
                                                >
                                                    <Field name="made_prior_claim">
                                                        {({ field, form }) => (
                                                            <Radio.Group
                                                                value={
                                                                    values?.made_prior_claim
                                                                }
                                                                onChange={(val) =>
                                                                    form.setFieldValue(
                                                                        field.name,
                                                                        val
                                                                    )
                                                                }
                                                            >
                                                                <Radio value={true}>
                                                                    Yes
                                                                </Radio>
                                                                <Radio value={false}>
                                                                    No
                                                                </Radio>
                                                            </Radio.Group>
                                                        )}
                                                    </Field>
                                                </FormItem>


                                            </div>

                                            {values?.made_prior_claim && (
                                                <div>
                                                    {/* Number of Prior Claims */}
                                                    <div className="flex flex-row gap-4">
                                                        <FormItem
                                                            className="flex-1"
                                                            label="Number of Prior Claims"
                                                            invalid={
                                                                errors.number_of_prior_claims &&
                                                                touched.number_of_prior_claims
                                                            }
                                                            errorMessage={
                                                                errors.number_of_prior_claims
                                                            }
                                                        >
                                                            <Field
                                                                type="number"
                                                                name="number_of_prior_claims"
                                                                placeholder=""
                                                                component={Input}
                                                            />
                                                        </FormItem>

                                                        {/* Average Qualifying Expenditure */}
                                                        <FormItem
                                                            className="flex-1"
                                                            label="Estimated Annual Qualifying Expenditure"
                                                            invalid={
                                                                errors.average_qualifying_expenditure &&
                                                                touched.average_qualifying_expenditure
                                                            }
                                                            errorMessage={
                                                                errors.average_qualifying_expenditure
                                                            }
                                                        >
                                                            <Field name="average_qualifying_expenditure">
                                                                {({
                                                                    field,
                                                                    form,
                                                                }) => (
                                                                    <FormNumericInput
                                                                        form={
                                                                            form
                                                                        }
                                                                        inputSuffix={
                                                                            '£'
                                                                        }
                                                                        thousandSeparator={
                                                                            true
                                                                        }
                                                                        field={
                                                                            field
                                                                        }
                                                                        decimalScale={
                                                                            3
                                                                        }
                                                                        placeholder=""
                                                                        onValueChange={(
                                                                            e
                                                                        ) => {
                                                                            let value =
                                                                                e.floatValue;
                                                                            form.setFieldValue(
                                                                                field.name,
                                                                                value
                                                                            );
                                                                        }}
                                                                        value={
                                                                            field.value
                                                                        }
                                                                    />
                                                                )}
                                                            </Field>
                                                        </FormItem>

                                                        {/* Expenditure Category */}
                                                        <FormItem
                                                            className="flex-1"
                                                            label="Expenditure Category"
                                                            invalid={
                                                                errors.expenditure_category &&
                                                                touched.expenditure_category
                                                            }
                                                            errorMessage={
                                                                errors.expenditure_category
                                                            }
                                                        >
                                                            <Field name="expenditure_category">
                                                                {({ field, form }) => {
                                                                    const options = [
                                                                        { value: "Payroll", label: "Payroll" },
                                                                        { value: "Subcontractors", label: "Subcontractors" },
                                                                        { value: "EPW", label: "EPW" },
                                                                        { value: "Consumables", label: "Consumables" },
                                                                        { value: "Software", label: "Software" },
                                                                        { value: "Hardware", label: "Hardware" },
                                                                        { value: "Utilities", label: "Utilities" },
                                                                    ];

                                                                    return (
                                                                        <Select
                                                                            field={field}
                                                                            form={form}
                                                                            options={options}
                                                                            value={options.find(option => option.value === field.value)}
                                                                            onChange={(option) => form.setFieldValue(field.name, option?.value)}
                                                                        />
                                                                    );
                                                                }}
                                                            </Field>
                                                        </FormItem>



                                                        {/* R&D Scheme */}
                                                        <FormItem
                                                            className="flex-1"
                                                            label="R&D Scheme"
                                                            invalid={
                                                                errors.r_and_d_scheme &&
                                                                touched.r_and_d_scheme
                                                            }
                                                            errorMessage={
                                                                errors?.r_and_d_scheme
                                                            }
                                                        >
                                                            <Field name="r_and_d_scheme">
                                                                {({
                                                                    field,
                                                                    form,
                                                                }) => (
                                                                    <Select
                                                                        field={
                                                                            field
                                                                        }
                                                                        form={form}
                                                                        options={
                                                                            rndSchemeOptions
                                                                        }
                                                                        value={rndSchemeOptions.filter(
                                                                            (
                                                                                option
                                                                            ) =>
                                                                                option.value ===
                                                                                values?.r_and_d_scheme
                                                                        )}
                                                                        onChange={(
                                                                            option
                                                                        ) =>
                                                                            form.setFieldValue(
                                                                                field.name,
                                                                                option?.value
                                                                            )
                                                                        }
                                                                    />
                                                                )}
                                                            </Field>
                                                        </FormItem>
                                                    </div>
                                                </div>
                                            )}
                                            {/* Add the Company Incorporation Date field */}
                                            <div className="flex flex-row gap-4">
                                                <FormItem
                                                    className="flex-1"
                                                    label="Company Incorporation Date"
                                                    invalid={errors.company_incorporation_date && touched.company_incorporation_date}
                                                    errorMessage={errors.company_incorporation_date}
                                                >
                                                    <Field name="company_incorporation_date">
                                                        {({ field, form }) => (
                                                            <DatePicker
                                                                field={field}
                                                                form={form}
                                                                value={values?.company_incorporation_date}
                                                                format="DD-MM-YYYY"
                                                                onChange={(date) => form.setFieldValue(field.name, date)}
                                                            />
                                                        )}
                                                    </Field>
                                                </FormItem>
                                            </div>

                                            {/* Previous Claims Subject to HMRC Enquiry */}
                                            <div className="flex flex-row gap-4">
                                                <FormItem
                                                    className="flex-1"
                                                    label="Previous Claims Subject to HMRC Enquiry"
                                                    invalid={
                                                        errors.previous_claims_hmrc_enquiry &&
                                                        touched.previous_claims_hmrc_enquiry
                                                    }
                                                    errorMessage={
                                                        errors.previous_claims_hmrc_enquiry
                                                    }
                                                >
                                                    <Field name="previous_claims_hmrc_enquiry">
                                                        {({ field, form }) => (
                                                            <Radio.Group
                                                                value={
                                                                    values?.previous_claims_hmrc_enquiry
                                                                }
                                                                onChange={(val) =>
                                                                    form.setFieldValue(
                                                                        field.name,
                                                                        val
                                                                    )
                                                                }
                                                            >
                                                                <Radio value={true}>
                                                                    Yes
                                                                </Radio>
                                                                <Radio value={false}>
                                                                    No
                                                                </Radio>
                                                            </Radio.Group>
                                                        )}
                                                    </Field>
                                                </FormItem>
                                            </div>

                                            {values?.previous_claims_hmrc_enquiry && (
                                                <div className="flex flex-row gap-4">
                                                    <FormItem
                                                        className="flex-1"
                                                        label="Previous Claims HMRC Enquiry Date"
                                                        invalid={
                                                            errors.previous_claim_hmrc_enquiry_date &&
                                                            touched.previous_claim_hmrc_enquiry_date
                                                        }
                                                        errorMessage={
                                                            errors.previous_claim_hmrc_enquiry_date
                                                        }
                                                    >
                                                        <Field
                                                            name="previous_claim_hmrc_enquiry_date"
                                                        >
                                                            {({
                                                                field,
                                                                form,
                                                            }) => (
                                                                <DatePicker
                                                                    field={field}
                                                                    form={form}
                                                                    value={
                                                                        values?.previous_claim_hmrc_enquiry_date
                                                                    }
                                                                    inputFormat="MM/YYYY"
                                                                    defaultView="month"
                                                                    onChange={(
                                                                        date
                                                                    ) => {
                                                                        form.setFieldValue(
                                                                            field.name,
                                                                            date
                                                                        )
                                                                    }}
                                                                />
                                                            )}
                                                        </Field>
                                                    </FormItem>
                                                </div>
                                            )}

                                            {/* Number of Projects to be Assessed */}
                                            <div className="flex flex-row gap-4">
                                                <FormItem
                                                    className="flex-1"
                                                    label="Number of Projects to be Assessed"
                                                    invalid={
                                                        errors.number_of_projects_to_assess &&
                                                        touched.number_of_projects_to_assess
                                                    }
                                                    errorMessage={
                                                        errors.number_of_projects_to_assess
                                                    }
                                                >
                                                    <Field
                                                        type="number"
                                                        name="number_of_projects_to_assess"
                                                        placeholder=""
                                                        component={Input}
                                                    />
                                                </FormItem>

                                                <FormItem
                                                    className="flex-1"
                                                    label="Number of Individuals Involved in Projects"
                                                    invalid={
                                                        errors.number_of_individuals_involved &&
                                                        touched.number_of_individuals_involved
                                                    }
                                                    errorMessage={
                                                        errors.number_of_individuals_involved
                                                    }
                                                >
                                                    <Field
                                                        type="number"
                                                        name="number_of_individuals_involved"
                                                        placeholder=""
                                                        component={Input}
                                                    />
                                                </FormItem>
                                            </div>

                                            {/* Estimated Payroll Cost */}
                                            <div className="flex flex-row gap-4">
                                                <FormItem
                                                    className="flex-1"
                                                    label="Estimated Payroll Cost"
                                                    invalid={
                                                        errors.estimated_payroll_cost &&
                                                        touched.estimated_payroll_cost
                                                    }
                                                    errorMessage={
                                                        errors.estimated_payroll_cost
                                                    }
                                                >
                                                    <Field
                                                        name="estimated_payroll_cost"
                                                    >
                                                        {({ field, form }) => (
                                                            <FormNumericInput
                                                                form={form}
                                                                inputSuffix={'£'}
                                                                thousandSeparator={true}
                                                                field={field}
                                                                decimalScale={2}
                                                                placeholder=""
                                                                onValueChange={(e) => {
                                                                    let value =
                                                                        e.floatValue
                                                                    form.setFieldValue(
                                                                        field.name,
                                                                        value
                                                                    )
                                                                }}
                                                                value={field.value}
                                                            />
                                                        )}
                                                    </Field>
                                                </FormItem>

                                                {/* Estimated External Contractors Cost */}
                                                <FormItem
                                                    className="flex-1"
                                                    label="Estimated External Contractors Cost"
                                                    invalid={
                                                        errors.estimated_external_contractors_cost &&
                                                        touched.estimated_external_contractors_cost
                                                    }
                                                    errorMessage={
                                                        errors.estimated_external_contractors_cost
                                                    }
                                                >
                                                    <Field
                                                        name="estimated_external_contractors_cost"
                                                    >
                                                        {({ field, form }) => (
                                                            <FormNumericInput
                                                                form={form}
                                                                inputSuffix={'£'}
                                                                thousandSeparator={true}
                                                                field={field}
                                                                decimalScale={2}
                                                                placeholder=""
                                                                onValueChange={(e) => {
                                                                    let value =
                                                                        e.floatValue
                                                                    form.setFieldValue(
                                                                        field.name,
                                                                        value
                                                                    )
                                                                }}
                                                                value={field.value}
                                                            />
                                                        )}
                                                    </Field>
                                                </FormItem>
                                            </div>

                                            {/* Estimated Software/Materials/Consumables Cost */}
                                            <div className="flex flex-row gap-4">
                                                <FormItem
                                                    className="flex-1"
                                                    label="Estimated Software/Materials/Consumables Cost"
                                                    invalid={
                                                        errors.estimated_software_materials_cost &&
                                                        touched.estimated_software_materials_cost
                                                    }
                                                    errorMessage={
                                                        errors.estimated_software_materials_cost
                                                    }
                                                >
                                                    <Field
                                                        name="estimated_software_materials_cost"
                                                    >
                                                        {({ field, form }) => (
                                                            <FormNumericInput
                                                                form={form}
                                                                inputSuffix={'£'}
                                                                thousandSeparator={true}
                                                                field={field}
                                                                decimalScale={2}
                                                                placeholder=""
                                                                onValueChange={(e) => {
                                                                    let value =
                                                                        e.floatValue
                                                                    form.setFieldValue(
                                                                        field.name,
                                                                        value
                                                                    )
                                                                }}
                                                                value={field.value}
                                                            />
                                                        )}
                                                    </Field>
                                                </FormItem>
                                            </div>

                                            {/* Outstanding R&D Claims with HMRC */}
                                            <div className="flex flex-row gap-4">
                                                <FormItem
                                                    className="flex-1"
                                                    label="Outstanding R&D Claims with HMRC"
                                                    invalid={
                                                        errors.r_and_d_claims_outstanding_hmrc &&
                                                        touched.r_and_d_claims_outstanding_hmrc
                                                    }
                                                    errorMessage={
                                                        errors.r_and_d_claims_outstanding_hmrc
                                                    }
                                                >
                                                    <Field name="r_and_d_claims_outstanding_hmrc">
                                                        {({ field, form }) => (
                                                            <Radio.Group
                                                                value={
                                                                    values?.r_and_d_claims_outstanding_hmrc
                                                                }
                                                                onChange={(val) =>
                                                                    form.setFieldValue(
                                                                        field.name,
                                                                        val
                                                                    )
                                                                }
                                                            >
                                                                <Radio value={true}>
                                                                    Yes
                                                                </Radio>
                                                                <Radio value={false}>
                                                                    No
                                                                </Radio>
                                                            </Radio.Group>
                                                        )}
                                                    </Field>
                                                </FormItem>
                                            </div>

                                            {values?.r_and_d_claims_outstanding_hmrc && (
                                                <div className="flex flex-row gap-4">
                                                    {/* R&D Claims Outstanding Period */}
                                                    <FormItem
                                                        className="flex-1"
                                                        label="R&D Claim(s) Period to be made"
                                                        invalid={
                                                            errors.r_and_d_claims_outstanding_period &&
                                                            touched.r_and_d_claims_outstanding_period
                                                        }
                                                        errorMessage={errors.r_and_d_claims_outstanding_period}
                                                    >
                                                        <Field
                                                            name="r_and_d_claims_outstanding_period"
                                                        >
                                                            {({ field, form }) => {
                                                                const options = [
                                                                    { value: "2022", label: "2022" },
                                                                    { value: "2023", label: "2023" },
                                                                    { value: "2024", label: "2024" },
                                                                    { value: "2025", label: "2025" },
                                                                ];

                                                                return (
                                                                    <Select
                                                                        field={field}
                                                                        form={form}
                                                                        options={options}
                                                                        value={options.find(option => option.value === field.value)}
                                                                        onChange={(option) => form.setFieldValue(field.name, option?.value)}
                                                                    />
                                                                );
                                                            }}
                                                        </Field>
                                                    </FormItem>


                                                    {/* Submission Date of Outstanding R&D Claims */}
                                                    <FormItem
                                                        className="flex-1"
                                                        label="Submission Date Preference of Outstanding R&D Claims"
                                                        invalid={
                                                            errors.r_and_d_claims_outstanding_submitted_date &&
                                                            touched.r_and_d_claims_outstanding_submitted_date
                                                        }
                                                        errorMessage={
                                                            errors.r_and_d_claims_outstanding_submitted_date
                                                        }
                                                    >
                                                        <Field name="r_and_d_claims_outstanding_submitted_date">
                                                            {({
                                                                field,
                                                                form,
                                                            }) => (
                                                                <DatePicker
                                                                    field={field}
                                                                    form={form}
                                                                    value={
                                                                        values?.r_and_d_claims_outstanding_submitted_date
                                                                    }
                                                                    format="DD-MM-YYYY"
                                                                    onChange={(date) =>
                                                                        form.setFieldValue(
                                                                            field.name,
                                                                            date
                                                                        )
                                                                    }
                                                                />
                                                            )}
                                                        </Field>
                                                    </FormItem>
                                                </div>
                                            )}

                                            <div className="flex flex-row gap-4">
                                                {/* Grant Funding Received */}
                                                <FormItem
                                                    className="flex-1"
                                                    label="Grant Funding Received in the above period"
                                                    invalid={
                                                        errors.received_grant_funding &&
                                                        touched.received_grant_funding
                                                    }
                                                    errorMessage={
                                                        errors.received_grant_funding
                                                    }
                                                >
                                                    <Field name="received_grant_funding">
                                                        {({ field, form }) => (
                                                            <Radio.Group
                                                                value={
                                                                    values?.received_grant_funding
                                                                }
                                                                onChange={(val) =>
                                                                    form.setFieldValue(
                                                                        field.name,
                                                                        val
                                                                    )
                                                                }
                                                            >
                                                                <Radio value={true}>
                                                                    Yes
                                                                </Radio>
                                                                <Radio value={false}>
                                                                    No
                                                                </Radio>
                                                            </Radio.Group>
                                                        )}
                                                    </Field>
                                                </FormItem>
                                            </div>

                                            {values?.received_grant_funding && (
                                                <>
                                                    {/* Grant Funding Amount */}
                                                    <div className="flex flex-row gap-4">
                                                        <FormItem
                                                            className="flex-1"
                                                            label="Grant Funding Amount"
                                                            invalid={
                                                                errors.grant_funding_amount &&
                                                                touched.grant_funding_amount
                                                            }
                                                            errorMessage={
                                                                errors.grant_funding_amount
                                                            }
                                                        >
                                                            <Field name="grant_funding_amount">
                                                                {({ field, form }) => (
                                                                    <FormNumericInput
                                                                        form={form}
                                                                        inputSuffix={'£'}
                                                                        thousandSeparator={true}
                                                                        field={field}
                                                                        decimalScale={2}
                                                                        placeholder=""
                                                                        onValueChange={(e) => {
                                                                            let value = e.floatValue
                                                                            form.setFieldValue(
                                                                                field.name,
                                                                                value
                                                                            )
                                                                        }}
                                                                        value={field.value}
                                                                    />
                                                                )}
                                                            </Field>
                                                        </FormItem>
                                                    </div>

                                                    {/* Type of Funding */}
                                                    <div className="flex flex-row gap-4">
                                                        <FormItem
                                                            className="flex-1"
                                                            label="Type of Funding"
                                                            invalid={
                                                                errors.type_of_funding &&
                                                                touched.type_of_funding
                                                            }
                                                            errorMessage={
                                                                errors.type_of_funding
                                                            }
                                                        >
                                                            <Field
                                                                name="type_of_funding"
                                                                placeholder=""
                                                                component={Input}
                                                            />
                                                        </FormItem>
                                                    </div>

                                                    {/* Date of Funding */}
                                                    <div className="flex flex-row gap-4">
                                                        <FormItem
                                                            className="flex-1"
                                                            label="Date of Funding"
                                                            invalid={
                                                                errors.date_of_funding &&
                                                                touched.date_of_funding
                                                            }
                                                            errorMessage={
                                                                errors.date_of_funding
                                                            }
                                                        >
                                                            <Field name="date_of_funding">
                                                                {({ field, form }) => (
                                                                    <DatePicker
                                                                        field={field}
                                                                        form={form}
                                                                        value={values?.date_of_funding}
                                                                        format="MM/YYYY"
                                                                        onChange={(date) =>
                                                                            form.setFieldValue(
                                                                                field.name,
                                                                                date
                                                                            )
                                                                        }
                                                                    />
                                                                )}
                                                            </Field>
                                                        </FormItem>
                                                    </div>
                                                </>
                                            )}


                                            {/* Review Previous Claims */}
                                            <div className="flex flex-row gap-4">
                                                <FormItem
                                                    className="flex-1"
                                                    label="Review Previous Claims"
                                                    invalid={
                                                        errors.review_previous_claims &&
                                                        touched.review_previous_claims
                                                    }
                                                    errorMessage={
                                                        errors.review_previous_claims
                                                    }
                                                >
                                                    <Field name="review_previous_claims">
                                                        {({ field, form }) => (
                                                            <Radio.Group
                                                                value={
                                                                    values?.review_previous_claims
                                                                }
                                                                onChange={(val) =>
                                                                    form.setFieldValue(
                                                                        field.name,
                                                                        val
                                                                    )
                                                                }
                                                            >
                                                                <Radio value={true}>
                                                                    Yes
                                                                </Radio>
                                                                <Radio value={false}>
                                                                    No
                                                                </Radio>
                                                            </Radio.Group>
                                                        )}
                                                    </Field>
                                                </FormItem>
                                            </div>

                                            {values?.review_previous_claims && (
                                                <div className="flex flex-row gap-4">
                                                    <FormItem
                                                        className="flex-1"
                                                        label="Review year"
                                                        invalid={
                                                            errors.review_previous_claims_year &&
                                                            touched.review_previous_claims_year
                                                        }
                                                        errorMessage={
                                                            errors.review_previous_claims_year
                                                        }
                                                    >
                                                        <Field name="review_previous_claims_year">
                                                            {({
                                                                field,
                                                                form,
                                                            }) => (
                                                                <DatePicker
                                                                    field={field}
                                                                    form={form}
                                                                    value={
                                                                        values?.review_previous_claims_year
                                                                    }
                                                                    inputformat="MM/YYYY"
                                                                    defaultView='month'
                                                                    onChange={(date) =>
                                                                        form.setFieldValue(
                                                                            field.name,
                                                                            date
                                                                        )
                                                                    }
                                                                />
                                                            )}
                                                        </Field>
                                                    </FormItem>
                                                </div>
                                            )}
                                        </div>
                                    </Loading>

                                    {/* Form Navigation Buttons */}
                                    <div className="mt-7 flex flex-row text-right gap-7">
                                        <Button
                                            block
                                            id="prev"
                                            type="button"
                                            onClick={() => navigate(-1)}
                                        >
                                            {'Previous'}
                                        </Button>
                                        <Button
                                            block
                                            type="submit"
                                            variant="solid"
                                            loading={isSubmitting}
                                        >
                                            {'Next'}
                                        </Button>
                                    </div>
                                </FormContainer>
                            </Form>
                        </div>
                    )}
                </Formik>
            )}
        </div>
    )
}
