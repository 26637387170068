import { FormNumericInput, Loading } from 'components/shared'
import {
    Button,
    DatePicker,
    FormContainer,
    FormItem,
    Input,
    Notification,
    Radio,
    Select as CustomSelect,
    toast,
} from 'components/ui'
import { STEPS } from 'components/ui/utils/constant'
import { getErrorMessage } from 'components/ui/utils/errorMessage'
import dayjs from 'dayjs'
import { Field, Form, Formik, useFormikContext } from 'formik'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
    apiAddCompanyTenderRequirements,
    apiGetCompanyBackgroundInfo,
    apiGetCompanyInfo,
    apiGetCompanyInfoByRegistationNumber,
    apiGetCompanyTenderRequirements,
    apiGetEmployeeProfile,
    apiGetProfileProgress,
    apiGetSectors,
    apiUpdateCompanyBasicInfo,
    apiUpdateEmployeeProfile,
} from 'services/StepService'
import {
    setIsTenderRequirementsCompleted,
    stepChange,
} from 'store/step/stepSlice'
import * as Yup from 'yup'
import CreatableSelect from 'react-select/creatable'
import makeAnimated from 'react-select/animated'
import Select from 'react-select'

const formikRef = React.createRef()

const animatedComponents = makeAnimated()

// 24 hours, 48 hours, 72 hours, 1 week
export const turnaround_time = [
    { value: '1_week', label: '1 Week' },
    { value: '2_week', label: '2 Weeks' },
]

export const turnaround_time_claim = [
    { value: '1_week', label: '1 Week' },
    { value: '2_week', label: '2 Weeks' },
    { value: '3_week', label: '3 Weeks' },
    { value: '4_week', label: '4 Weeks' },
    { value: '5_week', label: '5 Weeks' },
    { value: '6_week', label: '6 Weeks' },
    { value: '7_plus_week', label: '7+ Weeks' },
]
export const submitionTypes = [
    { value: 'own_accountant', label: 'Own Accountant' },
    { value: 'supplier', label: 'Supplier' },
    { value: 'self', label: 'Self' },
]

export const requirementImportantOptions = [
    { value: 1, label: '1' },
    { value: 2, label: '2' },
    { value: 3, label: '3' },
    { value: 4, label: '4' },
    { value: 5, label: '5' },
]
export const paymentStructureOptions = [
    { value: 'fixed', label: 'Fixed' },
    { value: 'contingent', label: 'Success Fee (Contingent)' },
    { value: 'hybrid', label: 'Hybrid Fee (Fixed + Contingent)' },
]
export const contractLengthOptions = [
    { value: '1_year', label: '1 Year' },
    { value: '2_years', label: '2 Years' },
    { value: '3_years', label: '3 Years' },
    { value: '4_years', label: '4 Years' },
    { value: '5_years', label: '5 Years' },
]
export const feesIncludeOptions = [
    { value: 'include', label: 'Include HMRC Defense within the Fee Proposal' },
    { value: 'separate', label: 'Quote Separately as part of Tender Proposal' },
    { value: 'not_required', label: 'Not Required'}
]

export const multiSelectOptions = [
    { value: 'foo', label: 'Foo' },
    { value: 'bar', label: 'Bar' },
]

export const dedicatedTeamOptions = [
    { value: 'yes', label: 'Yes' },
    { value: 'no', label: 'No' },
]

export const enquiryRateOptions = [
    { value: 'low', label: 'Low (less than 5%)' },
    { value: 'medium', label: 'Medium (5 – 10%)' },
    { value: 'high', label: 'High (10% +)' },
]

export const methodEngagementOptions = [
    { value: 'remote', label: 'Remote' },
    { value: 'on_site', label: 'On-site' },
    { value: 'hybrid', label: 'Hybrid' },
]

const validationSchema = Yup.object().shape({
    turnaround_time_supplier_confirmation: Yup.string()
        .required('Please enter turnaround time supplier confirmation')
        .nullable(),
    turnaround_time_claim_submission: Yup.string()
        .required('Please enter turnaround time claim submission')
        .nullable(),
    submission_type: Yup.string()
        .required('Please enter submission type')
        .nullable(),
    requirement_importance_1: Yup.number()
        .required('Please enter requirement importance 1')
        .nullable(),
    payment_structure_preference: Yup.string()
        .required('Please enter payment structure preference')
        .nullable(),
    contract_length: Yup.string()
        .required('Please enter contract length')
        .nullable(),
    fees_include_hmrc_defence: Yup.string()
        .required('Please enter fees include hmrc defence')
        .nullable(),
    requirement_importance_2: Yup.number()
        .required('Please enter requirement importance 2')
        .nullable(),
    sector_expertise: Yup.array()
        .required('Please enter sector expertise')
        .nullable(),
    dedicated_team: Yup.string()
        .required('Please enter dedicated team')
        .nullable(),
    enquiry_rate: Yup.string().required('Please enter enquiry rate').nullable(),
    requirement_importance_3: Yup.number()
        .required('Please enter requirement importance 3')
        .nullable(),
    method_of_engagement: Yup.string()
        .required('Please enter method of engagement')
        .nullable(),
    requirement_importance_4: Yup.number()
        .required('Please enter requirement importance 4')
        .nullable(),
})

export default function Step4() {
    const dispatch = useDispatch()
    const [companyTenderRequirements, setCompanyTenderRequirements] = useState(
        {}
    )
    const [loading, setLoading] = useState(true)
    const [isSubmitRegistration, setIsSubmitRegistration] = useState(false)
    const [sectors, setSectors] = useState([])
    const [disableForm, setDisableForm] = useState(true)
    const navigate = useNavigate()

    useEffect(() => {
        dispatch(stepChange(STEPS.TENDER_REQUIREMENTS))
        fetchSectors()
    }, [])
    const fetchSectors = async () => {
        try {
            const resp = await apiGetSectors()
            if (resp.data?.status === 'success') {
                const list = resp?.data?.data
                const mapSectors = list.map((item) => {
                    const obj = {
                        value: item.code,
                        label: item.description,
                    }
                    return obj
                })
                setSectors(mapSectors)
                fetchData(mapSectors)
            }

        } catch (error) {}
    }
    const fetchData = async (sectors) => {
        try {
            setLoading(true)
            const resp = await apiGetCompanyTenderRequirements()
            if (resp.status === 200) {
                const is_completed = resp?.data?.data?.is_completed
                dispatch(setIsTenderRequirementsCompleted(is_completed))
                setDisableForm(is_completed)
                const data = resp?.data?.data?.tender_requirements
                pathFormValue(data, sectors)
            }
            setLoading(false)
        } catch (error) {
            toast.push(
                <Notification title={'Message'} type={'danger'}>
                    {getErrorMessage(error)}
                </Notification>
            )
            setLoading(false)
        }
    }

    const onNext = async (values, setSubmitting) => {
        setSubmitting(true)
        try {
            const payload = {
                ...values,
            }
            if (payload.sector_expertise) {
                const newSectors = payload.sector_expertise.map(
                    (item) => item.label
                )
                payload.sector_expertise = newSectors
            }
            const result = await apiAddCompanyTenderRequirements(payload)
            if (result.status === 200) {
                const is_completed = result?.data?.data?.is_completed
                dispatch(setIsTenderRequirementsCompleted(is_completed))
                const checkProgress = await apiGetProfileProgress()
                const {
                    is_background_completed,
                    is_tender_requirements_completed,
                    is_employee_completed,
                    is_company_profile_completed,
                } = checkProgress?.data?.data
                if (
                    is_background_completed &&
                    is_tender_requirements_completed &&
                    is_employee_completed &&
                    is_company_profile_completed
                ) {
                    navigate('/rfqForm')
                } else {
                    let msg = ''
                    if (!is_employee_completed) msg = 'Step 1 not completed'
                    if (!is_company_profile_completed)
                        msg = 'Step 2 not completed'
                    if (!is_background_completed) msg = 'Step 3 not completed'
                    if (!is_tender_requirements_completed)
                        msg = 'Step 4 not completed'
                    toast.push(
                        <Notification title={'Message'} type={'danger'}>
                            {msg}
                        </Notification>
                    )
                }
            }
            setSubmitting(false)
        } catch (error) {
            toast.push(
                <Notification title={'Message'} type={'danger'}>
                    {getErrorMessage(error)}
                </Notification>
            )
            setSubmitting(false)
        }
    }

    const pathFormValue = (data, sectors) => {
        const init = {
            ...data,
        }
        if (init.sector_expertise) {
            const mapSectors = []
            init.sector_expertise.forEach((label) => {
                const checkCode = sectors.find(
                    (sector) => sector.label === label
                )
                if (checkCode) mapSectors.push(checkCode)
            })
            init.sector_expertise = mapSectors
        }
        setCompanyTenderRequirements(init)
    }
    return (
        <div className="w-full flex flex-col px-4 py-8 items-center">
            {loading ? (
                <Loading loading={loading} />
            ) : (
                <Formik
                    enableReinitialize
                    validationSchema={validationSchema}
                    innerRef={formikRef}
                    initialValues={companyTenderRequirements}
                    onSubmit={(values, { setSubmitting }) => {
                        setSubmitting(true)
                        onNext(values, setSubmitting)
                    }}
                >
                    {({ values, touched, errors, isSubmitting }) => (
                        <div className="w-full">
                            <Form>
                                <FormContainer>
                                    <Loading
                                        loading={isSubmitRegistration}
                                        type="cover"
                                    >
                                        <div
                                            style={{ height: '67vh' }}
                                            className="overflow-auto"
                                        >
                                            <div className="bg-slate-50 px-6 pt-6 rounded-md mb-4">
                                                <div className="flex flex-row gap-4">
                                                    <FormItem
                                                        className="flex-1"
                                                        label="Turnaround Time for Supplier Tender Proposal"
                                                        invalid={
                                                            errors.turnaround_time_supplier_confirmation &&
                                                            touched.turnaround_time_supplier_confirmation
                                                        }
                                                        errorMessage={
                                                            errors?.turnaround_time_supplier_confirmation
                                                        }
                                                    >
                                                        <Field
                                                            disabled={
                                                                disableForm
                                                            }
                                                            name="turnaround_time_supplier_confirmation"
                                                        >
                                                            {({
                                                                field,
                                                                form,
                                                            }) => (
                                                                <CustomSelect
                                                                    field={
                                                                        field
                                                                    }
                                                                    form={form}
                                                                    options={
                                                                        turnaround_time
                                                                    }
                                                                    value={turnaround_time.filter(
                                                                        (
                                                                            option
                                                                        ) =>
                                                                            option.value ===
                                                                            values?.turnaround_time_supplier_confirmation
                                                                    )}
                                                                    onChange={(
                                                                        option
                                                                    ) =>
                                                                        form.setFieldValue(
                                                                            field.name,
                                                                            option?.value
                                                                        )
                                                                    }
                                                                />
                                                            )}
                                                        </Field>
                                                    </FormItem>
                                                    <FormItem
                                                        className="flex-1"
                                                        label="Turnaround Time for Claim Submission"
                                                        invalid={
                                                            errors.turnaround_time_claim_submission &&
                                                            touched.turnaround_time_claim_submission
                                                        }
                                                        errorMessage={
                                                            errors?.turnaround_time_claim_submission
                                                        }
                                                    >
                                                        <Field
                                                            disabled={
                                                                disableForm
                                                            }
                                                            name="turnaround_time_claim_submission"
                                                        >
                                                            {({
                                                                field,
                                                                form,
                                                            }) => (
                                                                <CustomSelect
                                                                    field={
                                                                        field
                                                                    }
                                                                    form={form}
                                                                    options={
                                                                        turnaround_time_claim
                                                                    }
                                                                    value={turnaround_time_claim.filter(
                                                                        (
                                                                            option
                                                                        ) =>
                                                                            option.value ===
                                                                            values?.turnaround_time_claim_submission
                                                                    )}
                                                                    onChange={(
                                                                        option
                                                                    ) =>
                                                                        form.setFieldValue(
                                                                            field.name,
                                                                            option?.value
                                                                        )
                                                                    }
                                                                />
                                                            )}
                                                        </Field>
                                                    </FormItem>
                                                </div>
                                                <div className="flex flex-row gap-4">
                                                    <FormItem
                                                        className="flex-1"
                                                        label="Claim Submission to HMRC"
                                                        invalid={
                                                            errors.submission_type &&
                                                            touched.submission_type
                                                        }
                                                        errorMessage={
                                                            errors?.submission_type
                                                        }
                                                    >
                                                        <Field
                                                            disabled={
                                                                disableForm
                                                            }
                                                            name="submission_type"
                                                        >
                                                            {({
                                                                field,
                                                                form,
                                                            }) => (
                                                                <CustomSelect
                                                                    field={
                                                                        field
                                                                    }
                                                                    form={form}
                                                                    options={
                                                                        submitionTypes
                                                                    }
                                                                    value={submitionTypes.filter(
                                                                        (
                                                                            option
                                                                        ) =>
                                                                            option.value ===
                                                                            values?.submission_type
                                                                    )}
                                                                    onChange={(
                                                                        option
                                                                    ) =>
                                                                        form.setFieldValue(
                                                                            field.name,
                                                                            option?.value
                                                                        )
                                                                    }
                                                                />
                                                            )}
                                                        </Field>
                                                    </FormItem>
                                                    <FormItem
                                                        className="flex-1"
                                                        label="Importance of Requirement"
                                                        invalid={
                                                            errors.requirement_importance_1 &&
                                                            touched.requirement_importance_1
                                                        }
                                                        errorMessage={
                                                            errors?.requirement_importance_1
                                                        }
                                                    >
                                                        <Field
                                                            disabled={
                                                                disableForm
                                                            }
                                                            name="requirement_importance_1"
                                                        >
                                                            {({
                                                                field,
                                                                form,
                                                            }) => (
                                                                <CustomSelect
                                                                    field={
                                                                        field
                                                                    }
                                                                    form={form}
                                                                    options={
                                                                        requirementImportantOptions
                                                                    }
                                                                    value={requirementImportantOptions.filter(
                                                                        (
                                                                            option
                                                                        ) =>
                                                                            option.value ===
                                                                            values?.requirement_importance_1
                                                                    )}
                                                                    onChange={(
                                                                        option
                                                                    ) =>
                                                                        form.setFieldValue(
                                                                            field.name,
                                                                            option?.value
                                                                        )
                                                                    }
                                                                />
                                                            )}
                                                        </Field>
                                                    </FormItem>
                                                </div>
                                            </div>
                                            <div className="bg-slate-50 px-6 pt-6 rounded-md mb-4">
                                                <div className="flex flex-row gap-4">
                                                    <FormItem
                                                        className="flex-1"
                                                        label="Preference of Payment Structure"
                                                        invalid={
                                                            errors.payment_structure_preference &&
                                                            touched.payment_structure_preference
                                                        }
                                                        errorMessage={
                                                            errors?.payment_structure_preference
                                                        }
                                                    >
                                                        <Field
                                                            disabled={
                                                                disableForm
                                                            }
                                                            name="payment_structure_preference"
                                                        >
                                                            {({
                                                                field,
                                                                form,
                                                            }) => (
                                                                <CustomSelect
                                                                    field={
                                                                        field
                                                                    }
                                                                    form={form}
                                                                    options={
                                                                        paymentStructureOptions
                                                                    }
                                                                    value={paymentStructureOptions.filter(
                                                                        (
                                                                            option
                                                                        ) =>
                                                                            option.value ===
                                                                            values?.payment_structure_preference
                                                                    )}
                                                                    onChange={(
                                                                        option
                                                                    ) =>
                                                                        form.setFieldValue(
                                                                            field.name,
                                                                            option?.value
                                                                        )
                                                                    }
                                                                />
                                                            )}
                                                        </Field>
                                                    </FormItem>
                                                    <FormItem
                                                        className="flex-1"
                                                        label="Contract Length"
                                                        invalid={
                                                            errors.contract_length &&
                                                            touched.contract_length
                                                        }
                                                        errorMessage={
                                                            errors?.contract_length
                                                        }
                                                    >
                                                        <Field
                                                            disabled={
                                                                disableForm
                                                            }
                                                            name="contract_length"
                                                        >
                                                            {({
                                                                field,
                                                                form,
                                                            }) => (
                                                                <CustomSelect
                                                                    field={
                                                                        field
                                                                    }
                                                                    form={form}
                                                                    options={
                                                                        contractLengthOptions
                                                                    }
                                                                    value={contractLengthOptions.filter(
                                                                        (
                                                                            option
                                                                        ) =>
                                                                            option.value ===
                                                                            values?.contract_length
                                                                    )}
                                                                    onChange={(
                                                                        option
                                                                    ) =>
                                                                        form.setFieldValue(
                                                                            field.name,
                                                                            option?.value
                                                                        )
                                                                    }
                                                                />
                                                            )}
                                                        </Field>
                                                    </FormItem>
                                                </div>
                                                <div className="flex flex-row gap-4">
                                                    <FormItem
                                                        className="flex-1"
                                                        label="Fees Include HMRC Enquiry Defence"
                                                        invalid={
                                                            errors.fees_include_hmrc_defence &&
                                                            touched.fees_include_hmrc_defence
                                                        }
                                                        errorMessage={
                                                            errors?.fees_include_hmrc_defence
                                                        }
                                                    >
                                                        <Field
                                                            disabled={
                                                                disableForm
                                                            }
                                                            name="fees_include_hmrc_defence"
                                                        >
                                                            {({
                                                                field,
                                                                form,
                                                            }) => (
                                                                <CustomSelect
                                                                    field={
                                                                        field
                                                                    }
                                                                    form={form}
                                                                    options={
                                                                        feesIncludeOptions
                                                                    }
                                                                    value={feesIncludeOptions.filter(
                                                                        (
                                                                            option
                                                                        ) =>
                                                                            option.value ===
                                                                            values?.fees_include_hmrc_defence
                                                                    )}
                                                                    onChange={(
                                                                        option
                                                                    ) =>
                                                                        form.setFieldValue(
                                                                            field.name,
                                                                            option?.value
                                                                        )
                                                                    }
                                                                />
                                                            )}
                                                        </Field>
                                                    </FormItem>
                                                    <FormItem
                                                        className="flex-1"
                                                        label="Importance of Payment and Terms"
                                                        invalid={
                                                            errors.requirement_importance_2 &&
                                                            touched.requirement_importance_2
                                                        }
                                                        errorMessage={
                                                            errors?.requirement_importance_2
                                                        }
                                                    >
                                                        <Field name="requirement_importance_2">
                                                            {({
                                                                field,
                                                                form,
                                                            }) => (
                                                                <CustomSelect
                                                                    field={
                                                                        field
                                                                    }
                                                                    form={form}
                                                                    options={
                                                                        requirementImportantOptions
                                                                    }
                                                                    value={requirementImportantOptions.filter(
                                                                        (
                                                                            option
                                                                        ) =>
                                                                            option.value ===
                                                                            values?.requirement_importance_2
                                                                    )}
                                                                    onChange={(
                                                                        option
                                                                    ) =>
                                                                        form.setFieldValue(
                                                                            field.name,
                                                                            option?.value
                                                                        )
                                                                    }
                                                                />
                                                            )}
                                                        </Field>
                                                    </FormItem>
                                                </div>
                                            </div>
                                            <div className="bg-slate-50 px-6 pt-6 rounded-md mb-4">
                                                <div className="flex flex-row gap-4">
                                                    <FormItem
                                                        className="flex-1"
                                                        label="Sector Expertise"
                                                        invalid={Boolean(
                                                            errors.sector_expertise &&
                                                                touched.sector_expertise
                                                        )}
                                                        errorMessage={
                                                            errors.sector_expertise
                                                        }
                                                    >
                                                        <Field name="sector_expertise">
                                                            {({
                                                                field,
                                                                form,
                                                            }) => (
                                                                <CustomSelect
                                                                    isMulti
                                                                    closeMenuOnSelect={
                                                                        false
                                                                    }
                                                                    components={
                                                                        animatedComponents
                                                                    }
                                                                    componentAs={
                                                                        Select
                                                                    }
                                                                    field={
                                                                        field
                                                                    }
                                                                    form={form}
                                                                    options={
                                                                        sectors
                                                                    }
                                                                    value={
                                                                        values?.sector_expertise
                                                                    }
                                                                    onChange={(
                                                                        option
                                                                    ) => {
                                                                        form.setFieldValue(
                                                                            field.name,
                                                                            option
                                                                        )
                                                                    }}
                                                                />
                                                            )}
                                                        </Field>
                                                    </FormItem>
                                                </div>
                                                <div className="flex flex-row gap-4">
                                                    <FormItem
                                                        className="flex-1"
                                                        label="Dedicated Team from Start to Finish"
                                                        invalid={
                                                            errors.dedicated_team &&
                                                            touched.dedicated_team
                                                        }
                                                        errorMessage={
                                                            errors?.dedicated_team
                                                        }
                                                    >
                                                        <Field
                                                            disabled={
                                                                disableForm
                                                            }
                                                            name="dedicated_team"
                                                        >
                                                            {({
                                                                field,
                                                                form,
                                                            }) => (
                                                                <CustomSelect
                                                                    field={
                                                                        field
                                                                    }
                                                                    form={form}
                                                                    options={
                                                                        dedicatedTeamOptions
                                                                    }
                                                                    value={dedicatedTeamOptions.filter(
                                                                        (
                                                                            option
                                                                        ) =>
                                                                            option.value ===
                                                                            values?.dedicated_team
                                                                    )}
                                                                    onChange={(
                                                                        option
                                                                    ) =>
                                                                        form.setFieldValue(
                                                                            field.name,
                                                                            option?.value
                                                                        )
                                                                    }
                                                                />
                                                            )}
                                                        </Field>
                                                    </FormItem>
                                                    <FormItem
                                                        className="flex-1"
                                                        label="HMRC Enquiry Rate"
                                                        invalid={
                                                            errors.enquiry_rate &&
                                                            touched.enquiry_rate
                                                        }
                                                        errorMessage={
                                                            errors?.enquiry_rate
                                                        }
                                                    >
                                                        <Field
                                                            disabled={
                                                                disableForm
                                                            }
                                                            name="enquiry_rate"
                                                        >
                                                            {({
                                                                field,
                                                                form,
                                                            }) => (
                                                                <CustomSelect
                                                                    field={
                                                                        field
                                                                    }
                                                                    form={form}
                                                                    options={
                                                                        enquiryRateOptions
                                                                    }
                                                                    value={enquiryRateOptions.filter(
                                                                        (
                                                                            option
                                                                        ) =>
                                                                            option.value ===
                                                                            values?.enquiry_rate
                                                                    )}
                                                                    onChange={(
                                                                        option
                                                                    ) =>
                                                                        form.setFieldValue(
                                                                            field.name,
                                                                            option?.value
                                                                        )
                                                                    }
                                                                />
                                                            )}
                                                        </Field>
                                                    </FormItem>
                                                    <FormItem
                                                        className="flex-1"
                                                        label="Importance of Expertise Requirement"
                                                        invalid={
                                                            errors.requirement_importance_3 &&
                                                            touched.requirement_importance_3
                                                        }
                                                        errorMessage={
                                                            errors?.requirement_importance_3
                                                        }
                                                    >
                                                        <Field name="requirement_importance_3">
                                                            {({
                                                                field,
                                                                form,
                                                            }) => (
                                                                <CustomSelect
                                                                    field={
                                                                        field
                                                                    }
                                                                    form={form}
                                                                    options={
                                                                        requirementImportantOptions
                                                                    }
                                                                    value={requirementImportantOptions.filter(
                                                                        (
                                                                            option
                                                                        ) =>
                                                                            option.value ===
                                                                            values?.requirement_importance_3
                                                                    )}
                                                                    onChange={(
                                                                        option
                                                                    ) =>
                                                                        form.setFieldValue(
                                                                            field.name,
                                                                            option?.value
                                                                        )
                                                                    }
                                                                />
                                                            )}
                                                        </Field>
                                                    </FormItem>
                                                </div>
                                            </div>
                                            <div className="bg-slate-50 px-6 pt-6 rounded-md mb-4">
                                                <div className="flex flex-row gap-4">
                                                    <FormItem
                                                        className="flex-1"
                                                        label="Method of Claim Assessment"
                                                        invalid={
                                                            errors.method_of_engagement &&
                                                            touched.method_of_engagement
                                                        }
                                                        errorMessage={
                                                            errors?.method_of_engagement
                                                        }
                                                    >
                                                        <Field
                                                            disabled={
                                                                disableForm
                                                            }
                                                            name="method_of_engagement"
                                                        >
                                                            {({
                                                                field,
                                                                form,
                                                            }) => (
                                                                <CustomSelect
                                                                    field={
                                                                        field
                                                                    }
                                                                    form={form}
                                                                    options={
                                                                        methodEngagementOptions
                                                                    }
                                                                    value={methodEngagementOptions.filter(
                                                                        (
                                                                            option
                                                                        ) =>
                                                                            option.value ===
                                                                            values?.method_of_engagement
                                                                    )}
                                                                    onChange={(
                                                                        option
                                                                    ) =>
                                                                        form.setFieldValue(
                                                                            field.name,
                                                                            option?.value
                                                                        )
                                                                    }
                                                                />
                                                            )}
                                                        </Field>
                                                    </FormItem>
                                                    <FormItem
                                                        className="flex-1"
                                                        label="Importance of Engagement Preferences"
                                                        invalid={
                                                            errors.requirement_importance_4 &&
                                                            touched.requirement_importance_4
                                                        }
                                                        errorMessage={
                                                            errors?.requirement_importance_4
                                                        }
                                                    >
                                                        <Field
                                                            disabled={
                                                                disableForm
                                                            }
                                                            name="requirement_importance_4"
                                                        >
                                                            {({
                                                                field,
                                                                form,
                                                            }) => (
                                                                <CustomSelect
                                                                    field={
                                                                        field
                                                                    }
                                                                    form={form}
                                                                    options={
                                                                        requirementImportantOptions
                                                                    }
                                                                    value={requirementImportantOptions.filter(
                                                                        (
                                                                            option
                                                                        ) =>
                                                                            option.value ===
                                                                            values?.requirement_importance_4
                                                                    )}
                                                                    onChange={(
                                                                        option
                                                                    ) =>
                                                                        form.setFieldValue(
                                                                            field.name,
                                                                            option?.value
                                                                        )
                                                                    }
                                                                />
                                                            )}
                                                        </Field>
                                                    </FormItem>
                                                </div>
                                            </div>
                                        </div>
                                    </Loading>

                                    <div className="mt-7 flex flex-row text-right gap-7">
                                        <Button
                                            block
                                            id="prev"
                                            type="button"
                                            onClick={() => navigate(-1)}
                                        >
                                            {'Previous'}
                                        </Button>
                                        <Button
                                            block
                                            type="submit"
                                            variant="solid"
                                            loading={isSubmitting}
                                        >
                                            {'Next'}
                                        </Button>
                                    </div>
                                </FormContainer>
                            </Form>
                        </div>
                    )}
                </Formik>
            )}
        </div>
    )
}
