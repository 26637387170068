import { method } from 'lodash'
import ApiService from './ApiService'

export async function apiSignIn(data) {
    return ApiService.fetchData({
        url: '/token',
        method: 'post',
        data,
    })
}

export async function apiCheckEmailStatus(data) {
    return ApiService.fetchData({
        url: '/check-email-status',
        method: 'post',
        data,
    })
}

export async function apiSignUp(data) {
    return ApiService.fetchData({
        url: '/register',
        method: 'post',
        data,
    })
}
export async function apiSubmitVerificationMethod(data) {
    return ApiService.fetchData({
        url: '/submit-verification-method',
        method: 'post',
        data,
    })
}

export async function apiVerifyRegister(path, data) {
    return ApiService.fetchData({
        url: path,
        method: 'post',
        data,
    })
}

export async function apiSignOut(data) {
    return ApiService.fetchData({
        url: '/logout',
        method: 'post',
        data,
    })
}

export async function apiForgotPassword(data) {
    return ApiService.fetchData({
        url: '/forgot-password',
        method: 'post',
        data,
    })
}

export async function apiResetPasswordConfirm(data, params) {
    return ApiService.fetchData({
        url: `/reset-password-confirm/${params}`,
        method: 'post',
        data,
    })
}

export async function apiResetPassword(data) {
    return ApiService.fetchData({
        url: '/reset-password',
        method: 'post',
        data,
    })
}

export async function apiSetPasswordSupplier(data, params) {
    return ApiService.fetchData({
        url: `/set-password/${params}`,
        method: 'post',
        data,
    })
}

export async function apiGetUserInfo() {
    return ApiService.fetchData({
        url: '/me',
        method: 'get',
    })
}
export async function apiSupplierRegister(data) {
    return ApiService.fetchData({
        url: '/supplier-register',
        method: 'post',
        data,
    })
}
export async function apiAcceptTerms(status) {
    const data = { terms_accepted: status }
    return ApiService.fetchData({
        url: '/accept-terms',
        method: 'post',
        data,
    })
}
