import { Button, Dialog, ScrollBar } from 'components/ui'
import React, { useEffect, useState } from 'react'
import { termClient, termSupplier } from './term'
import { StickyFooter } from 'components/shared'
import { apiAcceptTerms } from 'services/AuthService'
import { useDispatch, useSelector } from 'react-redux'
import { initialState, setUser } from 'store/auth/userSlice'
import useAuth from 'utils/hooks/useAuth'

const TermItem = ({ term = {} }) => {
    return (
        <div className="flex flex-col mb-3">
            <b className="mb-2">{term.title}</b>
            {term.children.map((k, i) => (
                <span className="ml-4 mb-2" key={i}>
                    {k}
                </span>
            ))}
        </div>
    )
}

export default function TermDialog({
    termTitle = '',
    isOpen = false,
    setIsOpen,
    onAccept: propOnAccept
}) {
    const [isBottom, setIsBottom] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [isLoadingLogout, setIsLoadingLogout] = useState(false)
    const { user, } = useSelector((state) => state.auth)
    const { is_supplier } = useSelector((state) => state.auth.user)
    const dispatch = useDispatch()
    const { signOut, authenticated} = useAuth()

    const handleScroll = (e) => {
        // const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;

        const scrollTop = e.target.scrollTop
        const scrollHeight = e.target.scrollHeight
        const clientHeight = e.target.clientHeight
        const bottom = scrollTop + clientHeight > scrollHeight - 100
        if(bottom){
            setIsBottom(bottom)
        }
    }

    const onDecline = async () => {
        setIsLoadingLogout(true)
        signOut()
    }

    useEffect(() => {
      return () => {
        setIsLoading(false)
        setIsLoadingLogout(false)
      }
    }, [])
    
    
    const onAccept = async () => {
        try {
            setIsLoading(true)
            const resp = await apiAcceptTerms(true)
            let userInfo = { ...user }
            userInfo.terms_accepted = true
            dispatch(setUser(userInfo))
            setIsLoading(false)
            if (propOnAccept) {
                propOnAccept()
            }
        } catch (error) {
            setIsLoading(false)
        }
    }

    const renderTerm = () => {
        let term = termClient
        if(is_supplier) term = termSupplier
        return (
            term.map((term, i) => (
                <TermItem key={i} term={term} />
            ))
        )
    }

    return (
        <Dialog
            isOpen={isOpen}
            width={1000}
            height={'85vh'}
            closable={false}
            contentClassName="pb-0 px-0"
        >
            <ScrollBar className="relative" onScroll={handleScroll}>
                <div className="flex flex-col h-full ">
                    <h3 className="mb-8 mt-3 text-center px-6">{termTitle}</h3>
                    <div className="px-6">
                        {renderTerm()}
                    </div>

                    <StickyFooter className="px-8 flex items-center justify-center py-4 bg-gray-100 bottom-0 w-full rounded-b-lg">
                        <div className="flex items-center gap-10">
                            <Button loading={isLoadingLogout} onClick={onDecline} size="md">
                                Decline
                            </Button>
                            <Button
                                loading={isLoading}
                                disabled={!isBottom}
                                onClick={onAccept}
                                size="md"
                                variant="solid"
                            >
                                Accept
                            </Button>
                        </div>
                    </StickyFooter>
                </div>
            </ScrollBar>
        </Dialog>
    )
}
