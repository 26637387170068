import { useSelector, useDispatch } from 'react-redux'
import { setUser, initialState } from 'store/auth/userSlice'
import {
    apiGetUserInfo,
    apiSignIn,
    apiSignOut,
    apiSignUp,
} from 'services/AuthService'
import { onSignInSuccess, onSignOutSuccess } from 'store/auth/sessionSlice'
import appConfig from 'configs/app.config'
import { REDIRECT_URL_KEY } from 'constants/app.constant'
import { useNavigate } from 'react-router-dom'
import useQuery from './useQuery'
import { getErrorMessage } from 'components/ui/utils/errorMessage'
import { REDIRECT_PATH } from 'utils/mapUserPermissions'

function useAuth() {
    const dispatch = useDispatch()

    const navigate = useNavigate()

    const query = useQuery()

    const { token, signedIn, refresh } = useSelector(
        (state) => state.auth.session
    )

    const signIn = async (values) => {
        try {
            const resp = await apiSignIn(values)
            if (resp.data) {
                dispatch(onSignInSuccess(resp.data))
                if (resp.data.user) {
                    dispatch(
                        setUser(
                            resp.data.user || {
                                avatar: '',
                                userName: 'Anonymous',
                                authority: ['USER'],
                                email: '',
                            }
                        )
                    )
                }
                const userResult = await apiGetUserInfo()
                dispatch(setUser(userResult.data.data || {}))
                const { is_admin, is_supplier } = userResult.data.data
                const redirectTo = is_admin
                    ? REDIRECT_PATH.ADMIN
                    : is_supplier
                    ? REDIRECT_PATH.SUPPLIER
                    : REDIRECT_PATH.USER
                navigate(redirectTo)
                return {
                    status: 'success',
                    message: '',
                }
            }
        } catch (error) {
            if (error.response && error.response.data && error.response.data.detail) {
                return {
                    status: 'failed',
                    message: error.response.data.detail[0],
                }
            } else {
                return {
                    status: 'failed',
                    message: 'An unexpected error occurred',
                }
            }
        }
    }

    const signUp = async (values) => {
        try {
            const resp = await apiSignUp(values)
            if (resp.data) {
                const { success } = resp.data
                // const { token } = resp.data
                // dispatch(onSignInSuccess(token))
                // if (resp.data.user) {
                //     dispatch(
                //         setUser(
                //             resp.data.user || {
                //                 avatar: '',
                //                 userName: 'Anonymous',
                //                 authority: ['USER'],
                //                 email: '',
                //             }
                //         )
                //     )
                // }
                // const redirectUrl = query.get(REDIRECT_URL_KEY)
                // navigate(
                //     redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath
                // )
                return {
                    status: 'success',
                    message: success,
                }
            }
        } catch (errors) {
            return {
                status: 'failed',
                message: getErrorMessage(errors, 'password'),
            }
        }
    }

    const handleSignOut = () => {
        dispatch(onSignOutSuccess())
        dispatch(setUser(initialState))
        navigate(appConfig.unAuthenticatedEntryPath)
    }

    const signOut = async () => {
        try {
            const data = {
                refresh: refresh,
            }
            apiSignOut(data)
            handleSignOut()
        } catch (error) {
            handleSignOut()
        }
        
    }
    return {
        authenticated: Boolean(token && signedIn),
        signIn,
        signUp,
        signOut,
    }
}

export default useAuth
