import { combineReducers, createSlice } from '@reduxjs/toolkit'
import appConfig from 'configs/app.config'

const initialState = {
    stepIndex: 0,
    is_background_completed: false,
    is_tender_requirements_completed: false,
    is_employee_completed: false,
    is_company_profile_completed: false,
    new_tender_dialog: false
}

const stepSlice = createSlice({
    name: 'step',
    initialState,
    reducers: {
        stepChange: (state, action) => {
            state.stepIndex = action.payload
        },
        setIsBackgroundCompleted: (state, action) => {
            state.is_background_completed = action.payload
        },
        setIsTenderRequirementsCompleted: (state, action) => {
            state.is_tender_requirements_completed = action.payload
        },
        setIsEmployeeCompleted: (state, action) => {
            state.is_employee_completed = action.payload
        },
        setIsCompanyProfileCompleted: (state, action) => {
            state.is_company_profile_completed = action.payload
        },
        toggleNewTenderDialog: (state, action) => {
            state.new_tender_dialog = action.payload
        },
    },
})
const data = stepSlice.reducer
export const {
    stepChange,
    setIsBackgroundCompleted,
    setIsTenderRequirementsCompleted,
    setIsEmployeeCompleted,
    setIsCompanyProfileCompleted,
    toggleNewTenderDialog
} = stepSlice.actions

// export default localeSlice.reducer
const stepReducer = combineReducers({ data })
export default stepReducer
