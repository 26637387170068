import React, {
    memo,
    useMemo,
    lazy,
    Suspense,
    useEffect,
    useState,
} from 'react'
import { useLocation } from 'react-router-dom'
import { Loading, StickyFooter } from 'components/shared'
import { useDispatch, useSelector } from 'react-redux'
import {
    LAYOUT_TYPE_CLASSIC,
    LAYOUT_TYPE_MODERN,
    LAYOUT_TYPE_SIMPLE,
    LAYOUT_TYPE_STACKED_SIDE,
    LAYOUT_TYPE_DECKED,
    LAYOUT_TYPE_BLANK,
} from 'constants/theme.constant'
import useAuth from 'utils/hooks/useAuth'
import useDirection from 'utils/hooks/useDirection'
import useLocale from 'utils/hooks/useLocale'
import { apiGetUserInfo } from 'services/AuthService'
import { setUser } from 'store/auth/userSlice'
import TermDialog from './TermDialog'
import { publicRoutes, protectedRoutes } from 'configs/routes.config'

const layouts = {
    [LAYOUT_TYPE_CLASSIC]: lazy(() => import('./ClassicLayout')),
    [LAYOUT_TYPE_MODERN]: lazy(() => import('./ModernLayout')),
    [LAYOUT_TYPE_STACKED_SIDE]: lazy(() => import('./StackedSideLayout')),
    [LAYOUT_TYPE_SIMPLE]: lazy(() => import('./SimpleLayout')),
    [LAYOUT_TYPE_DECKED]: lazy(() => import('./DeckedLayout')),
    [LAYOUT_TYPE_BLANK]: lazy(() => import('./BlankLayout')),
}

const Layout = () => {
    const layoutType = useSelector((state) => state.theme.layout.type)
    const { user } = useSelector((state) => state.auth)
    const { authenticated } = useAuth()
    const location = useLocation()
    const dispatch = useDispatch()
    const [dialogIsOpen, setIsOpen] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    useDirection()

    useLocale()

    const currentRoute = useMemo(() => {
        return authenticated
            ? protectedRoutes.find(route => route.path === location.pathname)
            : publicRoutes.find(route => route.path === location.pathname);
    }, [authenticated, location.pathname]);

    const AppLayout = useMemo(() => {
        console.log("Authenticated:", authenticated);
        console.log("Current pathname:", location.pathname);
        if (authenticated) {
            console.log("Using authenticated layout");
            const AuthenticatedLayout = layouts[layoutType];
            return ({ children }) => <AuthenticatedLayout>{children}</AuthenticatedLayout>;
        }
        console.log("Current route:", currentRoute);
        if (currentRoute && currentRoute.layout === 'main') {
            console.log("Using MainLayout");
            return lazy(() => import('./MainLayout'));
        }
        console.log("Using AuthLayout");
        return lazy(() => import('./AuthLayout'));
    }, [layoutType, authenticated, location.pathname, currentRoute])

    useEffect(() => {
        if (authenticated) {
            fetchUserInfo()
        }
    }, [authenticated])

    useEffect(() => {
        if (user?.terms_accepted || user?.is_admin) {
            setIsOpen(false)
        }
    }, [user])

    useEffect(() => {
        console.log('Authentication status:', authenticated);
        console.log('User object:', user);
        console.log('Dialog open state:', dialogIsOpen);
    }, [authenticated, user, dialogIsOpen]);

    const fetchUserInfo = async () => {
        try {
            setIsLoading(true)
            const resp = await apiGetUserInfo()
            setIsLoading(false)
            if (resp.data.data) {
                dispatch(setUser(resp.data.data || {}))
                if (!resp.data.data.terms_accepted && !resp.data.data.is_admin) {
                    setIsOpen(true)
                }
            }
        } catch (error) {
            setIsLoading(false)
        }
    }

    console.log('Render TermDialog:', {
        authenticated,
        termsAccepted: user?.terms_accepted,
        isAdmin: user?.is_admin,
        dialogIsOpen
    });

    return (
        <Suspense
            fallback={
                <div className="flex flex-auto flex-col h-[100vh]">
                    <Loading loading={true} />
                </div>
            }
        >
            <AppLayout>
                {currentRoute && currentRoute.component && (
                    <currentRoute.component />
                )}
            </AppLayout>
            {authenticated && !user?.terms_accepted && !user?.is_admin && (
                <TermDialog 
                    isOpen={dialogIsOpen} 
                    setIsOpen={setIsOpen}
                    termTitle="Terms and Conditions"
                />
            )}
        </Suspense>
    )
}

export default memo(Layout)
