import ApiService from './ApiService'

export async function apiGetEmployeeProfile() {
    return ApiService.fetchData({
        url: '/company-profile/employee',
        method: 'get',
    })
}
export async function apiUpdateEmployeeProfile(data) {
    return ApiService.fetchData({
        url: '/company-profile/employee',
        method: 'post',
        data
    })
}

export async function apiGetCompanyInfo() {
    return ApiService.fetchData({
        url: '/company-profile/info',
        method: 'get',
    })
}
export async function apiGetCompanyInfoByRegistationNumber(data) {
    return ApiService.fetchData({
        url: '/fetch-company-info',
        method: 'post',
        data
    })
}
export async function apiUpdateCompanyBasicInfo(data) {
    return ApiService.fetchData({
        url: '/company-profile/info',
        method: 'post',
        data
    })
}

export async function apiGetCompanyBackgroundInfo() {
    return ApiService.fetchData({
        url: '/company-profile/background',
        method: 'get',
    })
}

export async function apiUpdateCompanyBackgroundInfo(data) {
    return ApiService.fetchData({
        url: '/company-profile/background',
        method: 'post',
        data
    })
}
export async function apiGetSectors() {
    return ApiService.fetchData({
        url: '/sectors',
        method: 'get',
    })
}

export async function apiGetCompanyTenderRequirements() {
    return ApiService.fetchData({
        url: '/company-profile/tender-req',
        method: 'get',
    })
}
export async function apiAddCompanyTenderRequirements(data) {
    return ApiService.fetchData({
        url: '/company-profile/tender-req',
        method: 'post',
        data
    })
}
export async function apiGetRFQForm() {
    return ApiService.fetchData({
        url: '/company-profile',
        method: 'get',
    })
}
export async function apiGetProfileProgress() {
    return ApiService.fetchData({
        url: '/profile-progress',
        method: 'get',
    })
}

export async function apiCreateNewTender(data) {
    return ApiService.fetchData({
        url: '/tender',
        method: 'post',
        data
    })
}
export async function apiGetMyTenders() {
    return ApiService.fetchData({
        url: '/tender',
        method: 'get',
    })
}

export async function apiShortlistBid(bidId) {
    return ApiService.fetchData({
        url: `/bids/${bidId}/shortlist`,
        method: 'post',
    });
}

export async function apiRejectBid(bidId) {
    return ApiService.fetchData({
        url: `/bids/${bidId}/reject`,
        method: 'post',
    });
}


