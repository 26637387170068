export const PERMISSION = {
    ADMIN: 'is_admin',
    SUPPLIER: 'is_supplier',
    USER: 'is_regular_user',
}

export const REDIRECT_PATH = {
    ADMIN: '/suppliers',
    SUPPLIER: '/supplier-tenders',
    USER: '/home',
}

export default function userPermissions(user) {
    let userPermissions = Object.keys(PERMISSION)
        .map((key) => {
            const field = PERMISSION[key]
            if (user[field]) return field
        })
        .filter(Boolean)
    return userPermissions
}

