import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
    avatar: '',
    userName: '',
    authority: [],
    is_admin: false,
    is_supplier: false,
    is_regular_user: true,
    email: '',
    company_name: '',
    is_tender_created: false,
    terms_accepted: false,
}

export const userSlice = createSlice({
    name: 'auth/user',
    initialState,
    reducers: {
        setUser: (_, action) => action.payload,
        userLoggedOut: () => initialState,
    },
})

export const { setUser } = userSlice.actions

export default userSlice.reducer
